<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="设备预警"></ls-title>

    <!-- 条件框 -->
    <div class="conddiv">
      <el-input v-model="condition" placeholder="设备ID" prefix-icon="el-icon-search" style="width:200px;" clearable></el-input>

      <el-date-picker v-model="dateTime" style="margin-left:5px;" type="daterange" range-separator="至" format="YYYY 年 MM 月 DD 日"
              value-format="YYYY-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期" clearable>
      </el-date-picker>

      <el-select style="margin-left:5px;width:110px;" v-model="dealType" placeholder="处理状态" size="large" clearable>
         <el-option v-for="item in dealTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select style="margin-left:5px;width:110px;" v-model="waringType" placeholder="预警类型" size="large" clearable>
         <el-option v-for="item in waringTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>

      <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="dataList" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column prop="createTime" label="预警时间" width="135" label-class-name="tablehead" align="center " >
        <template #default="scope">
          <span>{{scope.row.createTime.substring(0,16)}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="role == 1" prop="unitName" width="200" label="所属机构"  label-class-name="tablehead" align="center" />
      <el-table-column prop="deviceId" width="140" label="设备ID" label-class-name="tablehead" align="center " />
      <el-table-column prop="deviceName" width="120" label="设备名称" label-class-name="tablehead" align="center " />
      <el-table-column prop="deviceModel" width="80" label="设备型号" label-class-name="tablehead" align="center " />
      <el-table-column prop="userName" width="100" label="绑定用户" label-class-name="tablehead" align="center " />
      <el-table-column prop="warningTypeDescribe" width="100" label="预警类型" label-class-name="tablehead" align="center" />
      <el-table-column prop="describe" label="预警内容" label-class-name="tablehead" align="center" />
      <!-- <el-table-column prop="address" label="设备位置" label-class-name="tablehead" align="center" /> -->
      <el-table-column prop="dealState" width="80" label="处理状态" label-class-name="tablehead" align="center" >
        <template #default="scope">
          <span v-if="scope.row.dealState == 1" style="color:red">未处理</span>
          <span v-if="scope.row.dealState == 2" style="color:green">已处理</span>
          <span v-if="scope.row.dealState == 3" style="color:#FFB640">已忽略</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealName"  width="100" label="处理人" label-class-name="tablehead" align="center" />
      <el-table-column label="操作" fixed="right" label-class-name="tablehead" align="center " width="60">
        <template #default="scope">
          <span class="textbtn" @click="seeWarning(scope.row)">详情</span>
        </template>
      </el-table-column>
      <el-table-column v-if="role != 1" label="更多操作" fixed="right" label-class-name="tablehead" align="center " width="100">
        <template #default="scope">
          <span class="textbtn" @click="deal(scope.row,1)" v-if="scope.row.dealState != 3">处理</span> 
          <span v-if="scope.row.dealState == 1"> / </span>
          <span class="textbtn" @click="deal(scope.row,0)" v-if="scope.row.dealState == 1">忽略</span>
          <span class="textbtn"  v-if="scope.row.dealState == 3">--</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>
  <template v-if="dealPage">
    <DeviceWarningDeal ref="dealView" @update="update" @cancel="cancelDeal"></DeviceWarningDeal>
  </template>
  <template v-if="detailsPage">
    <DeviceWarningDetails ref="detailsView" @cancel="cancelDetails"></DeviceWarningDetails>
  </template>

</template>

<script>
import {
  getDeviceWarningInfos
} from '../../../api/api'
import until from '../../../until/until'
import session from "../../../store/store";
import DeviceWarningDeal from './DeviceWarningDeal.vue'
import DeviceWarningDetails from './DeviceWarningDetails.vue'

export default {
  components: {
    DeviceWarningDeal,
    DeviceWarningDetails
  },
  data() {
    return {
      emptyText:'加载中',
      mainPage:true,
      dealPage: false,
      detailsPage: false,
      role:0,
      condition: "",//设备ID搜索
      dateTime: '',//条件
      startTime:'',
      endTime:'',
      dealType:'',//设备状态
      dealTypeOptions: [
        {value: '0',label: '全部'},
        {value: '1',label: '未处理'}, 
        {value: '2',label: '已处理'}, 
        {value: '3',label: '已忽略'},
      ],
      waringType:'',
      waringTypeOptions: [
        {value: '0',label: '全部'},
        {value: '202',label: '围栏预警'}, 
      ],

      currentPage: 1,
      size: 10,
      total: 0,//总数
      dataList: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getDeviceWarningInfos();
    },
    update() {
      this.getDeviceWarningInfos();
    },
    search() {
      if (!until.isNULL(this.dateTime)) {
        this.startTime = this.dateTime[0],
        this.endTime = this.dateTime[1]
      }else {
        this.startTime = '',
        this.endTime = ''
      }
      this.getDeviceWarningInfos();
    },
    getDeviceWarningInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,//83d4d390ff8080810183d53065340003
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
        startTime:this.startTime,
        endTime:this.endTime,
      }
      if (!until.isNULL(this.waringType)) {
        param.warningType = this.waringType
      }
      if (!until.isNULL(this.dealType)) {
        param.dealType = this.dealType
      }
      getDeviceWarningInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    seeWarning(row) {
      this.mainPage = false;
      this.detailsPage = true;
      setTimeout(() => {
        this.$refs.detailsView.getParentData(row);
      }, 200);
    },
    cancelDetails() {
      this.mainPage = true;
      this.detailsPage = false;
    },
    deal(row,type) {
      this.dealPage = true;
      setTimeout(() => {
        this.$refs.dealView.getParentData(row,type);
      }, 200);
    },
    cancelDeal() {
      this.dealPage = false;
    },
      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getDeviceWarningInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDeviceWarningInfos();
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>

.tablehead {
  color: white;
}
</style>